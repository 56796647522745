.ck.ck-editor {
  display: flex;
  flex-direction: column-reverse;
}
.ck.ck-dropdown .ck-button.ck-dropdown__button::first {
  background: #373947;
  color: #fff;
  font-weight: bold;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  outline: none !important;
}

.ck.ck-editor__top {
  padding-left: 55px !important;
  margin-bottom: -4px !important;
}

.ck .ck-content {
  height: 60px !important;
}

.ck.ck-editor__editable {
  background: #fff !important;
  border: none !important;
  width: 100%;
  color: $light-purple;
}

.ck.ck-editor__editable {
  color: #4a5165;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  box-shadow: none !important;
}

.ck.ck-editor__top {
  .ck.ck-toolbar {
    border: none;
  }
  .ck.ck-heading-dropdown {
    background-color: $light-black;
    border-radius: 4px;
    font-size: 10px;
    .ck.ck-button.ck-off.ck-button_with-text.ck-dropdown__button {
      &:hover {
        background-color: $light-black;
      }
      &:active {
        background-color: $light-black;
        box-shadow: none;
        border: none;
      }
    }

    .ck.ck-button__label {
      color: $white;
    }
    .ck.ck-icon.ck-dropdown__arrow {
      color: $white;
    }
  }

  .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-off:active:focus,
  .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on:active:focus {
    box-shadow: none;
  }

  .ck.ck-button.ck-on:not(.ck-disabled):active,
  a.ck.ck-button.ck-on:not(.ck-disabled):active {
    background-color: $light-black;
    border: none;
  }
  .ck-toolbar__separator {
    background-color: #dce0ea !important;
  }
  .ck.ck-icon.ck-button__icon {
    color: $light-purple;
  }
}

.text-editor .add-media {
  position: absolute;
  left: 10px;
  bottom: 15px;
  z-index: 1;
}

.text-editor .post-message {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.text-editor .add-media button {
  background: none;
  border: none;
}
