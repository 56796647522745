.card-feed {
  // background-color: #fff;
  border-radius: $borderTwo;
  border: 0 solid #2b3234;
  // box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px;
  position: relative;
  background: #f9f9f9;
  background: linear-gradient(
    178.02deg,
    #f9f9f9 1.67%,
    rgba(255, 255, 255, 0) 16.86%
  );
}

.border-top {
  border-top: 1px solid #bdbdbd !important;
}

.footer-1 {
  margin-left: -43px;
}

.footer-2 {
  color: #13a9e8;
  font-weight: 500;
}

.ew-gray {
  color: #4f4f4f;
}

.post-body ul,
ol {
  list-style: auto !important;
  margin-left: 20px;
}

.card-active {
  width: 5px;
  background-color: $random-orange;
  height: 100%;
  position: absolute;
}
.card-active-fade {
  left: 0px;
  width: 50%;
  height: 100%;
  position: absolute;
  background: $random-orange;
  background: -webkit-linear-gradient(to right, $random-orange, transparent);
  background: linear-gradient(to right, $random-orange, transparent);
  opacity: 0.07;
}
