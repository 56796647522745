// Colors
// ---------------------------------------------------
.text-primary {
  color: $primary;
}
.text-secondary {
  color: $secondary;
}
.text-jellyfish {
  color: $jellyfish;
}
.text-neon-carrot {
  color: $neon-carrot;
}
.text-lavender {
  color: $lavender;
}
.text-bashful-pink {
  color: $bashful-blue;
}
.text-pale-teal {
  color: $pale-teal;
}
.text-light-sky-blue {
  color: $light-sky-blue;
}
.text-white {
  color: $white;
}
.text-gray-529 {
  color: $gray-529;
}
.text-gray-333 {
  color: $gray-333;
}
.text-gray-f4f {
  color: $gray-f4f;
}
.text-gray-282 {
  color: $gray-282;
}
.text-gray-333 {
  color: $gray-333;
}
.text-gray-dbd {
  color: $gray-dbd;
}
.text-gray-0e0 {
  color: $gray-0e0;
}

.text-random- {
  color: $random-purple-heart;
}
.text-random-raspberry {
  color: $random-raspberry !important;
}
.text-random-barney {
  color: $random-barney;
}
.text-random-azure-6f3 {
  color: $random-azure-6f3;
}
.text-random-azure-9f4 {
  color: $random-azure-9f4;
}
.text-random-topaz {
  color: $random-topaz;
}
.text-random-teal {
  color: $random-teal;
}
.text-random-dark-pastel-green {
  color: $random-dark-pastel-green;
}
.text-random-green-peas {
  color: $random-green-peas;
}
.text-random-cerulean-blue {
  color: $random-cerulean-blue;
}
.text-random-orange-peal {
  color: $random-orange-peal;
}
.text-random-portland-orange {
  color: $random-portland-orange;
}
.text-random-star-dust {
  color: $random-star-dust;
}
.text-random-blue-grey {
  color: $random-blue-grey;
}
.text-gradient-primary {
  color: $gradient-primary;
}
.text-random-orange {
  color: $random-orange;
}

// BGs
// ---------------------------------------------------
.bg-primary {
  background-color: $primary;
}
.bg-secondary {
  background-color: $secondary;
}
.bg-jellyfish {
  background-color: $jellyfish;
}
.bg-neon-carrot {
  background-color: $neon-carrot;
}
.bg-lavender {
  background-color: $lavender;
}
.bg-bashful-pink {
  background-color: $bashful-blue;
}

.bg-pale-teal {
  background-color: $pale-teal;
}
.bg-light-sky-blue {
  background-color: $light-sky-blue;
}

.bg-white {
  background-color: $white;
}
.bg-gray-529 {
  background-color: $gray-529;
}
.bg-gray-333 {
  background-color: $gray-333;
}
.bg-gray-f4f {
  background-color: $gray-f4f;
}
.bg-gray-282 {
  background-color: $gray-282;
}
.bg-gray-333 {
  background-color: $gray-333;
}
.bg-gray-dbd {
  background-color: $gray-dbd;
}
.bg-gray-0e0 {
  background-color: $gray-0e0;
}

.bg-random- {
  background-color: $random-purple-heart;
}
.bg-random-raspberry {
  background-color: $random-raspberry;
}
.bg-random-barney {
  background-color: $random-barney;
}
.bg-random-azure-6f3 {
  background-color: $random-azure-6f3;
}
.bg-random-azure-9f4 {
  background-color: $random-azure-9f4;
}
.bg-random-topaz {
  background-color: $random-topaz;
}
.bg-random-teal {
  background-color: $random-teal;
}
.bg-random-dark-pastel-green {
  background-color: $random-dark-pastel-green;
}
.bg-random-green-peas {
  background-color: $random-green-peas;
}
.bg-random-cerulean-blue {
  background-color: $random-cerulean-blue;
}
.bg-random-orange-peal {
  background-color: $random-orange-peal;
}
.bg-random-portland-orange {
  background-color: $random-portland-orange;
}
.bg-random-star-dust {
  background-color: $random-star-dust;
}
.bg-random-blue-grey {
  background-color: $random-blue-grey;
}
.bg-transparent {
  background-color: transparent;
}
.bg-gradient-primary {
  background: $gradient-primary;
}

.bg-secondary-gray {
  background-color: $secondary-gray;
}

.bg-random-orange {
  background-color: $random-orange;
}

// borders-colors
// ---------------------------------------------------
.border-primary {
  border-color: $primary;
}
.border-secondary {
  border-color: $secondary;
}
.border-jellyfish {
  border-color: $jellyfish;
}
.border-neon-carrot {
  border-color: $neon-carrot;
}
.border-lavender {
  border-color: $lavender;
}
.border-bashful-pink {
  border-color: $bashful-blue;
}

.border-pale-teal {
  border-color: $pale-teal;
}
.border-light-sky-blue {
  border-color: $light-sky-blue;
}

.border-white {
  border-color: $white;
}
.border-gray-529 {
  border-color: $gray-529;
}
.border-gray-333 {
  border-color: $gray-333;
}
.border-gray-f4f {
  border-color: $gray-f4f;
}
.border-gray-282 {
  border-color: $gray-282;
}
.border-gray-333 {
  border-color: $gray-333;
}
.border-gray-dbd {
  border-color: $gray-dbd;
}
.border-gray-0e0 {
  border-color: $gray-0e0;
}
.border-gray-5f0 {
  border-color: $gray-5f0;
}
.border-random- {
  border-color: $random-purple-heart;
}
.border-random-raspberry {
  border-color: $random-raspberry !important;
}
.border-random-barney {
  border-color: $random-barney;
}
.border-random-azure-6f3 {
  border-color: $random-azure-6f3;
}
.border-random-azure-9f4 {
  border-color: $random-azure-9f4;
}
.border-random-topaz {
  border-color: $random-topaz;
}
.border-random-teal {
  border-color: $random-teal;
}
.border-random-dark-pastel-green {
  border-color: $random-dark-pastel-green;
}
.border-random-green-peas {
  border-color: $random-green-peas;
}
.border-random-cerulean-blue {
  border-color: $random-cerulean-blue;
}
.border-random-orange-peal {
  border-color: $random-orange-peal;
}
.border-random-portland-orange {
  border-color: $random-portland-orange;
}
.border-random-star-dust {
  border-color: $random-star-dust;
}
.border-random-blue-grey {
  border-color: $random-blue-grey;
}
.border-gradient-primary {
  border-color: $gradient-primary;
}
.border-random-orange {
  border-color: $random-orange;
}
.border-normal-gray {
  border-color: $normal-gray;
}

// border-sizes
// ---------------------------------------------------
.border-w-1 {
  border-width: 1px !important;
}
.border-w-2 {
  border-width: 2px !important;
}
.border-w-3 {
  border-width: 3px !important;
}
.border-w-4 {
  border-width: 4px !important;
}
.border-w-5 {
  border-width: 5px !important;
}
.border-w-6 {
  border-width: 6px !important;
}
.border-w-7 {
  border-width: 7px !important;
}
.border-w-8 {
  border-width: 8px !important;
}
.border-w-9 {
  border-width: 9px !important;
}
.border-w-10 {
  border-width: 10px !important;
}

// border-style
// ---------------------------------------------------
.border-solid {
  border-style: solid;
}

//
// ---------------
.mt-30 {
  margin-top: 30px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-40 {
  margin-right: 40px;
}

//
//-----------------------------------------
.text-right {
  text-align: right;
}
.float-r {
  float: right;
}

.wid-84 {
  width: 84px;
  min-width: unset !important;
  margin-left: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.font-weight-bold {
  font-weight: bold;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}
.font-weight-400 {
  font-weight: 400;
}

.w-40 {
  width: 40%;
}

.text-center {
  text-align: center;
}

.bg-black-opacity {
  background-color: rgba(0, 0, 0, 0.7);
}

.w-95 {
  width: 95%;
}

.tag-dark-green {
  color: #36c55e;
}
.tag-light-green {
  color: #26a74a;
}
.tag-blue-2 {
  color: $tag-blue-2;
}
.tag-light-purple {
  color: #af4ed1;
}
.tag-pink {
  color: #e2669a;
}
.tag-dark-purple {
  color: #8666e2;
}
.tag-blue-1 {
  color: $tag-blue-1;
}
.tag-blue-3 {
  color: $tag-blue-3;
}

.tag-dark-yellow {
  color: #cba624;
}

.tag-dark-green-bg {
  background-color: rgba(54, 197, 94, 0.15);
}
.tag-dark-blue-bg {
  background-color: rgba(62, 155, 208, 0.15);
}
.tag-pink-bg {
  background-color: rgba(226, 102, 154, 0.15);
}
.tag-dark-purple-bg {
  background-color: rgba(134, 102, 226, 0.15);
}
.tag-light-blue-bg {
  background-color: rgba(45, 178, 207, 0.15);
}

.text-light-purple {
  color: #8892ad;
}

.tag-red-1 {
  color: $tag-red-1;
}

.text-light-black {
  color: $light-black;
}

.br-3 {
  border-radius: $borderThree;
}

.br-6 {
  border-radius: $borderSix;
}

.br-5 {
  border-radius: $borderFive;
}

.br-6 {
  border-radius: $borderSix;
}

.text-black-4a5 {
  color: $black-4a5;
}

.border-top {
  border-top: 1px;
}

.text-random-red {
  color: $random-red !important;
}

.border-random-red {
  border-color: $random-red !important;
}

.bg-linear-white {
  background: linear-gradient(180deg, #f4f6f8 0%, rgba(255, 255, 255, 0) 100%);
}

.h-90{
  height: 90% !important;
}
