.topic-main {
  width: 100%;
  // max-width: 242px;
  // max-height: 330px;
  background-color: transparent !important;
  height: 100vh;
  border: none !important;
  box-shadow: none !important;
  @include media-breakpoint-down(lg) {
    // margin-bottom: 5px;
  }
}

.accordion {
  width: 100%;
  background-color: $white;
  border-radius: $borderTwo;
  // border-bottom: 1px solid $normal-gray;
  h5 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: $black;
    margin: 0;
    text-align: center;
  }
  .notification-react {
    background-color: #f9f9f9;
    // margin: 0px 20px;
    // padding: 20px 0px;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }
}

.sidebar-left.topic-main .accordion-body {
  background-color: #006e9c;
  padding: 0px 0px 10px 0px !important;
}

.sidebar-left.topic-main .accordion-body li.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-right: 4px solid $neon-carrot;
}

.sidebar-left.topic-main .accordion-footer {
  padding: 12px 12px !important;
  line-height: 1;
  border-top: none;
}

.accordion-header {
  background-color: $bashful-blue;
  padding: 17px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: $white;
    margin: 0;
  }

  figure {
    margin: 0;
    line-height: 0;
  }

  &.close {
    border-radius: 10px;
  }

  &.round {
    border-radius: 10px;
    margin-bottom: 10px;
    @include media-breakpoint-down(lg) {
      border-radius: 0px;
      margin-bottom: 5px;
    }
  }
}

.accordion-body {
  padding: 10px 10px 10px 0;
  // background: $white;

  &.round {
    border-radius: 10px;
    margin-bottom: 10px;
    @include media-breakpoint-down(lg) {
      border-radius: 0px;
      margin-bottom: 5px;
    }
  }
}

.topic-nav {
  li {
    a {
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      padding: 15px 14px;
      display: block;
      color: #fff;
      position: relative;
    }
  }
}

.accordion-footer {
  padding: 15px 14px;
  // text-align: center;
  background: $bashful-blue;
  border-top: 1px solid $gray-dbd;
  // margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.inner-container {
  display: flex;
}

.content-container {
  // margin-right: 15px;
  // margin-left: 15px;
  // width: 100%;
  .attchements {
    margin-top: 10px;
    display: flex;
    // justify-content: space-between;
    align-items: end;
    .photo {
      background: transparent url("../../img/icons/image.png") center left
        no-repeat;
    }
    .video {
      background: #fff url("../../img/icons/video.png") center left no-repeat;
    }
    .template {
      background: #fff url("../../img/icons/templates.png") center left
        no-repeat;
    }
    .folder {
      background: #fff url("../../img/icons/folder.png") center left no-repeat;
    }
    .clicks {
      button {
        border: 0;
        // width: 100px;
        // padding-left: 35px;
        // margin-right: 44px;
        background-color: transparent;
      }
    }

    span {
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      color: $gray-f4f;
    }

    // .btn {
    //   min-width: 130px;
    //   height: 50px;
    // }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.feed-header {
  width: 100%;
  background: $white;
  // border-bottom: 1px solid $gray-dbd;
  border-radius: $borderTwo;

  &.text-editor {
    border-radius: $borderTwo;
    position: sticky;
    bottom: 0px;
    top: calc(100vh - 560px);
  }
  p {
    margin: 0;
  }
  &.round {
    border-radius: 10px 10px 0 0;
    @include media-breakpoint-down(lg) {
      border-radius: 0px;
    }
  }

  &.text-editor {
    // margin-top: 10px;
  }
  .topic-info {
    border-top: 1px solid $normal-gray;
  }
  // .dashboard {
  //   border-bottom: 1px solid $gray-dbd;
  //   border-top: none !important;
  // }
  .totalSection {
    border-top: 1px solid $gray-5f0;
    border-bottom: 1px solid $gray-5f0;
    // border-bottom: 1px solid $gray-dbd;
  }
}

.feed-header.text-editor.edit-mode {
  // background: #fdf1e4;
}

.privacy-list {
  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      position: relative;
      // p {
      //   margin: 0;
      //   font-size: 13px;
      //   line-height: 16px;
      //   font-weight: 600;
      //   color: $gray-f4f;
      //   margin-left: 10px;
      //   width: 100%;
      //   overflow: hidden;
      //   overflow-wrap: break-word;
      //   small {
      //     font-size: 11px;
      //     line-height: 14px;
      //     font-weight: 400;
      //     display: block;
      //     margin: 2px 0 0 0;
      //     color: $gray-282;
      //   }
      // }
    }
  }
}

.suggestions-list {
  ul {
    padding: 0 20px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      div {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }
      }
      p {
        margin: 0;
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
        color: $gray-f4f;
        margin-left: 10px;
        width: 95px;
        overflow: hidden;
        overflow-wrap: break-word;
        small {
          font-size: 11px;
          line-height: 14px;
          font-weight: 400;
          display: block;
          margin: 2px 0 0 0;
          color: $gray-282;
        }
      }
    }
  }
}

.feed-info {
  display: flex;
  justify-content: space-between;
  .profile-info {
    display: flex;
    align-items: center;
    figcaption {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      color: $gray-f4f;
      margin-left: 11px;
    }
  }

  .users-info {
    ul {
      display: flex;
      align-items: center;
      li {
        p {
          font-size: 12px;
          line-height: 14px;
          color: $gray-f4f;
        }

        figure {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background: $hippie-blue;
          border: 1px solid $users-border;
        }

        &:last-child {
          margin-left: 15px;
        }
      }
    }

    input[type="checkbox"] {
      background-color: $white;
    }
    input[type="checkbox"]:checked {
      background-color: transparent;
    }
    input[type="checkbox"]:focus {
      box-shadow: none;
      border-color: $normal-gray;
    }
  }
  .dashCircleCount {
    position: absolute;
    height: 18px !important;
    width: 26px !important;
    font-size: 10px;
    bottom: -22px;
    right: -12px;
    border-radius: 16px !important;
    color: $white;
    background-color: $random-dark-pastel-green !important;
  }
}

.modal-feed-info {
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid $gray-dbd;
  padding: 20px 10px 20px 10px;

  // align-items: center;
  .profile-info {
    display: flex;
    align-items: center;
    figcaption {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      color: $gray-f4f;
      margin-left: 11px;
    }
  }

  .users-info {
    ul {
      display: flex;
      align-items: center;
      li {
        p {
          font-size: 12px;
          line-height: 14px;
          color: $gray-f4f;
        }

        figure {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background: $hippie-blue;
          border: 1px solid $users-border;
        }

        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }
}

.feed-like-comment {
  padding-left: 59px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.updated-history {
  display: flex;
  align-items: center;
  position: relative;
  .editpostoption {
    display: none;
    position: absolute;
    right: 0;
    top: 20px;
    bottom: 100%;
    width: auto;
    height: max-content;
    background-color: $lime-blue;
    //padding: 16px 0px;
    padding: 0px;
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
      0px 0px 1px rgba(9, 30, 66, 0.31);
    border-radius: 3px;
    &.active {
      display: inline-flex;
    }
    button {
      background: transparent;
      text-align: center;
      border: none;
      width: 100%;
      //margin-bottom: 15px;
      padding: 8px 10px;
      color: $black;
      font-size: 14px;
      line-height: 16px;
    }
  }
  p {
    font-size: 12px;
    line-height: 14px;
    color: $gray-f4f;
    a {
      color: $bashful-blue;
      font-weight: 700;
    }
  }
  .btn-more-info {
    margin-left: 23px;
  }
}

.activity-tabs {
  width: 100%;
  background: $white;
  border-radius: $borderTwo;
  border-bottom: 1px solid $gray-5f0;
  img {
    margin-right: 10px;
  }

  .tab-one,
  .tab-two {
    // display: inline-flex;
    span {
      border: 0;
      margin: 0;
      color: $light-purple;
      // font-size: 14px;
      line-height: 19px;
      display: inline-flex;
      position: relative;
      padding: 17px 21px;
      cursor: pointer;

      i {
        width: 21px;
        height: 21px;
        display: inline-block;
        margin-right: 10px;
        background: url("../../img/icons/public-icon-black.svg") no-repeat;
        background-size: 100%;
      }
    }
    &.active {
      span {
        color: $tag-blue-3;
      }

      i {
        background-color: $tag-blue-3;
        border-radius: 100%;
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 2px;
        background-color: $tag-blue-3;
      }
    }
  }
}
.ck {
  .ck-toolbar {
    background: $white !important;
    border-radius: 0px !important;
  }
  .ck-content {
    border-radius: 0px !important;
    height: 132px;
  }
}

.likes {
  background: url("../../img/icons/heart-filled.png") no-repeat left center;
  height: 25px;
  width: 25px;
  padding-right: 20px;
  margin-right: 55px;
  cursor: pointer;
  span {
    font-size: 14px !important;
    margin-left: 34px;
  }
}
.unlike {
  background: url("../../img/icons/heart.png") no-repeat left center;
  height: 22px;
  width: 25px;
  padding-right: 20px;
  margin-right: 55px;
  cursor: pointer;
  span {
    font-size: 14px !important;
    margin-left: 34px;
  }
}

.comments {
  background: url("../../img/icons/comment.png") no-repeat left center;
  height: 22px;
  width: 24px;
  padding-right: 20px;
  cursor: pointer;
  &:focus {
    background: red;
  }
  span {
    font-size: 14px !important;
    margin-left: 34px;
  }
}

.open-modal-comments {
  background: url("../../img/icons/comment.png") no-repeat left center;
  height: 22px;
  width: 24px;
  border: 0;
  padding-right: 20px;
  cursor: pointer;
  // &:focus {
  //   background: red;
  // }
  span {
    font-size: 14px !important;
    margin-left: 34px;
  }
}
.modal-comments {
  height: 22px;
  width: 24px;

  font-size: 14px !important;
  margin-left: 17px;
}

.post-inner-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  padding: 100px 0px;
  .no-img {
    margin: 30px 0px;
  }
  .add-text {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    color: #4f4f4f;
    margin-left: 11px;
  }
}

.over-scroll {
  max-height: 100%;
  overflow-y: scroll;
}
.img-responsive {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

// .modal-dialog {
//   max-width: 500px !important;
// }
.removeImage {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #ff5722;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 2px;
  &::before {
    content: "\2716";
    color: #ff5722;
  }
}

.calenderWrp {
  background-color: $brink-blue;
  color: $white;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  height: 50px;
  width: 199px;
}

// .task-topic {
//   border-bottom: 1px solid $gray-dbd;
// }

.topic-attachments {
  border-radius: 5px;
}
.hover-element {
  display: none;
}
.post-body:hover > .hover-element {
  display: block;
}
.post-body:hover > .text .hover-element {
  display: inline-block;
}
.hover-element-none {
  display: none;
}
.modal-feed-info .post-body .btn-more-info {
  display: none;
}
.modal-feed-info:hover .btn-more-info {
  display: inline-block;
}
// .comments-body div:hover {
//   background: #eceff34a;
// }
.showBar {
  display: block;
}
.hideBar {
  display: none;
}
.sticky-topic-details-header {
  position: sticky;
  z-index: 9;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.sticky-topic-details-sidebar {
  position: sticky;
  // top: 120px;
  //z-index: 9;
}

.album-details-row {
  position: relative;
  margin: 0;
  margin-top: 17px;
  .album-img-col {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 30px;
    position: relative;
    height: 195px;
    margin-bottom: 20px;
    // .album-img {
    // }
    .album-footer {
      display: none;
      width: 91%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      top: 0;
      flex-direction: column;
      .album-footer-delete {
        display: flex;
        justify-content: end;
      }
      .album-footer-li-co {
        justify-content: end;
      }
    }
    &:hover {
      .album-footer {
        display: flex;
      }
    }
  }
  .album-img-col:nth-child(1n) {
    padding-left: 10px;
    padding-right: 10px;
  }
  .img-overlay {
    display: flex;
    position: absolute;
    justify-content: space-around;
    bottom: 5px;
    width: 100%;
  }
  .btn-comment {
    background: url("../../img/icons/comment-icon.svg") no-repeat 16px center;
  }
  .btn-like {
    background: url("../../img/icons/like-icon.svg") no-repeat 16px center;
  }
  .album-img {
    position: relative;
    width: 100%;
    height: 195px;
    border-radius: 4px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      max-height: 195px;
      height: 100%;
    }
    &:hover {
      .album-footer {
        display: block;
      }
    }
  }
  .album-img > div {
    height: 100%;
  }
}
.comments-body {
  max-height: 440px;
  overflow-y: scroll;
  overflow-x: hidden;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #bdbdbd;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 10px;
  border: 3px solid transparent;
}
.is-sticky {
  position: fixed;
  top: 120px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.feeds-topic-details {
  // max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  // height: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  //overflow: hidden;
  background: linear-gradient(
    180deg,
    #f9f9f9 0%,
    rgba(255, 255, 255, 0.59) 100%
  );
  // background: red;
}

.feeds-topic-details::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.feeds-container {
  max-height: calc(100vh - 395px);
  overflow: hidden;
  overflow-y: scroll;
}
.feeds-container-full {
  max-height: calc(100vh - 300px);
  overflow: hidden;
  overflow-y: scroll;
}

#feeds-container {
  background: linear-gradient(180deg, #fff 90%, #37394700 100%);
}
.feeds-container-album {
  max-height: calc(100vh - 350px);
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 350px);

  // &::-webkit-scrollbar {
  //   width: 5px;
  //   height: 8px;
  //   background-color: #aaa; /* or add it to the track */
  // }
  ::-webkit-scrollbar-thumb {
    background: #000;
  }
}
.feeds-container-album-full {
  max-height: calc(100vh - 360px);
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
}
.feeds-container-task-list {
  max-height: calc(100vh - 400px);
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 530px);
}
.feeds-container-task-list-full {
  max-height: calc(100vh - 290px);
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
}
.feeds-container-document {
  max-height: calc(100vh - 395px);
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
}
.feeds-container-document-full {
  max-height: calc(100vh - 290px);
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
}
#commentsDiv {
  max-height: calc(100vh - 400px);
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 430px);
}
.feeds-container-convo {
  max-height: calc(100vh - 400px);
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 400px);
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.comment-count {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 3px;
  background: #006e9c;
  //border: 2px solid #666;
  color: #fff;
  text-align: center;
  //line-height: 1;
}
.offcanvas {
  margin-top: 83px;
  width: 455px;
}
.offcanvas-backdrop.show {
  opacity: 0 !important;
}

.comment-bottom {
  position: sticky;
  bottom: 50px;
  z-index: 2;
}

.backPost {
  position: sticky;
  bottom: 0px;
  z-index: 2;
}

.topic-details-container-wrp {
  // height: calc(100vh - 10%);
  //border-right: 1px solid $gray-0e0;
  border-radius: 5px;
  .content-container {
    height: 100%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.topic-details-topics-sidebar {
  min-width: 250px;
  background-color: $bashful-blue;
  //background-color: #fff;
  border-right: 1px solid #e0e0e0;
  height: 100vh;
  overflow: hidden;
  .accordion {
    background-color: transparent;
  }
  .accordion-body {
    background: transparent;
    overflow-y: scroll;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    padding-right: 0;
  }
  .btn-show-more {
    color: #fff !important;
    background: transparent !important;
    -webkit-text-fill-color: white;
  }
}
.uploads-details-topics-sidebar {
  min-width: 250px;
  background-color: $bashful-blue;
  height: 100vh;
  overflow: hidden;
  .accordion {
    background-color: transparent;
  }
  .accordion-body {
    background: transparent;
    overflow-y: scroll;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .btn-show-more {
    color: #fff !important;
    background: transparent !important;
    -webkit-text-fill-color: white;
  }
}
.comment-sidebar {
  border-radius: 5px;
  background-color: $white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .comment-content {
    max-height: 250px;
    overflow: hidden;
    overflow-y: scroll;
    label {
      display: block;
      &:nth-child(2) {
        font-size: 12px;
        color: $gray-979;
      }
    }
  }
  .comments-count {
    overflow: hidden;
    // label {
    //   font-size: 14px;
    //   color: $gray-282;
    // }
  }
  .comments-section {
    height: 69%;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .comments-section-name {
      div {
        display: flex;
        align-items: center;
        label {
          //width: 100%;
          &:nth-child(1) {
            /*width: 90px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;*/
          }
          &:nth-child(2) {
            font-size: 12px;
            color: $gray-979;
          }
        }
      }
    }
    .comments-section-content {
      label {
        font-size: 14px;
        color: $gray-f4f;
        //word-break: break-all;
      }
    }
  }

  .comments-section-album {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .comments-section-name {
      div {
        display: flex;
        align-items: center;
        label {
          //width: 100%;
          &:nth-child(1) {
            /*width: 90px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;*/
          }
          &:nth-child(2) {
            font-size: 12px;
            color: $gray-979;
          }
        }
      }
    }
    .comments-section-content {
      label {
        font-size: 14px;
        color: $gray-f4f;
        //word-break: break-all;
      }
    }
  }
  .add-comment-sticky {
    position: sticky;
    bottom: 0px;
    //box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }
}

.task-list-feeds {
  height: 310px;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.text-area-block {
  border: 1px solid $gray-dbd;
}
.accordion-body-max-height {
  height: 100vh;
}
.notes-timeformat {
  width: 24px;
  padding-left: 20px;
  font-size: 12px;
  color: #979797;
}
// .comments-section-album {
//   height: calc(100% - 442px) !important;
// }
.topic-details-container-wrp.bg-white.col-lg-8 {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.topic-details-container-wrp.bg-white.col-lg-8::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.topic-main.dashboard .btn {
  color: #fff;
  background: #12a8e8;
}
.topic-main.dashboard {
  height: 100%;
}
.topic-details-topics-sidebar .accordion {
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
}
.topic-details-topics-sidebar .accordion::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.album-details-modal {
  .ant-modal-content {
    height: 95vh;
    background: rgb(116, 116, 116);
    background: radial-gradient(
      circle,
      rgba(116, 116, 116, 1) 0%,
      rgba(51, 51, 51, 1) 80%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  .ant-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ant-modal-close {
    display: none;
  }
  .carousel-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    .carousel-item {
      margin-right: 0px;
      float: none;
      width: 40%;
      // .position-relative {
      //
      // }
    }
  }
}
.edit-privacy-btn {
  border-left: 1px solid #bdbdbd;
  display: flex;
  align-items: center;
  color: $brink-blue;
  margin-left: 10px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  height: 18px;
}
.comment-label {
  label {
    display: block;

    font-size: 12px;
    color: $gray-979;
  }
}
.convo-details-wrapper {
  width: 100%;
}
.convo-details {
  border-radius: 4px;
  background: $secondary-gray;
  width: 100%;
  // padding: 8px;
}
.convo-reply {
  border-left: 1px solid #999;
  span {
    margin-left: 10px;
  }
}
.topic-details-container-wrp-full {
  position: absolute !important;
  z-index: 9999 !important;
  height: 100vh;
  width: 100%;
  top: 0;
}
.feed-gradient-opacity {
  position: absolute;
  width: 100%;
  height: 165px;
  //background: red;
  background: linear-gradient(
    180deg,
    rgba(55, 57, 71, 0) 0%,
    rgba(55, 57, 71, 0.06) 100%
  );
}

.note-item {
  border-radius: $borderFive;
}

.document-card-images {
  border-radius: $borderFive;
  overflow: hidden;
}

.note-footer {
  border-radius: $borderFive;
}

.activeNote {
  border: 1px solid $random-orange;
  background-color: #fff;
}

.doc-type {
  border-radius: 100px;
}
.vis-type {
  border: 1px solid $gray-0e0;
  border-radius: 100px;
}

.carousel-top-bar {
  position: absolute;
  width: 100%;
  z-index: 1;
}
.carousel-bottom-bar {
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
}

.carousel-top-bar-blur {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 53px;
  background-color: $black;
  filter: blur(50px);
  z-index: 0;
}

.carousel-bottom-bar-blur {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 53px;
  background-color: $black;
  filter: blur(50px);
  z-index: 0;
}
