.initialWrp {
  background-color: $bashful-blue;
  height: 100vh;
  padding-top: 82px;
  margin: 0 auto;
}
.initialModal {
  background-color: $bashful-blue;
  .skipBtn {
    background-color: $white;
    color: $brink-blue;
    width: 150px;
    margin: 20px 0px;
    &:hover {
      color: $brink-blue !important;
    }
    &:focus {
      color: $brink-blue !important;
    }
  }
  .modal-content {
    background-color: $bashful-blue;
    width: 742px;
    margin: 0 auto;
  }
  .initialCarousel {
    h2 {
      color: $white;
    }
    h5 {
      color: $white;
    }
    label {
      color: $white;
    }
    .slick-dots {
      position: absolute;
      top: -50px;
      left: 0;
      display: flex !important;
      justify-content: flex-start;
      margin: 0;
      width: 10%;
      height: 5%;
      li {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        overflow: hidden;
        button {
          height: 10px;
          background-color: $brink-blue;
          opacity: 1;
        }
      }
      .slick-active {
        button {
          height: 10px;
          background-color: $white;
          //   opacity: ;
        }
      }
    }
  }
}
