.post-body {
  .text {
    // background-color: $white;
    padding: 17px 27px;
    font-size: 16px;
    line-height: 22px;
    color: $gray-f4f;
  }
  .img {
    width: 100%;
    height: 150px;
    img {
      object-fit: cover;
      width: 100%;
      max-height: 150px;
    }
  }
  .folder-img {
    position: relative;
    width: 100%;
    height: 195px;
    img {
      object-fit: cover;
      width: 100%;
      max-height: 195px !important;
      height: 195px !important;
    }
    .modal-image-container {
      height: 100%;
    }
  }
  .folder-img > div {
    height: 100%;
  }
}

.folder-img-col img {
  //width: auto !important;
  height: auto;
  max-height: 100% !important;
}

.post-footer {
  position: relative;
  background-color: $white;
  padding: 19px 27px;
  height: 66px;
  border-top: 1px solid #bdbdbd;
  .footer-inner {
    display: flex;
    justify-items: center;
    align-items: center;
  }
  span {
    flex: 0 0 33.33%;
    font-size: 24px;
    line-height: 26px;
    color: #4f4f4f;
  }
  .btn-link {
    flex: 0 0 33.33%;
    height: 26px;
    padding: 0 0.75rem;
  }
  .save-post {
    cursor: pointer;
    position: absolute;
    right: 27px;
    top: -50%;
    display: block;
    background-image: url("../../img/save-file.png");
    height: 80px;
    width: 80px;
  }
  .dashboard-add-icon {
    position: absolute;
    background: radial-gradient($bashful-blue, $brink-blue);
    width: 60px;
    height: 60px;
    border-radius: 30px;
    top: -35px;
    right: 20px;
    border: 2px solid $white;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
}

.no-border {
  border-top: none !important;
  border-radius: 0px !important;
}

.topic-details {
  .post-footer {
    border-radius: 0;
    border-top: 1px solid $gray-dbd;
    .like-comments {
      display: flex;
      align-items: center;
    }
  }
  .footer-inner {
    justify-content: space-between;
    p {
      margin: 0;
    }
  }
}

.topic-main {
  background-color: #fff;
  border-radius: 8px;
  border: 0 solid #2b3234;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px;
  .close-icon {
    background: url("../../img/icons/close-icon.png") no-repeat center center;
    cursor: pointer;
    background-size: contain;
  }
  &.dashboard {
    background-color: $white;
    border-radius: $borderTwo;
    max-height: 100%;
    .accordion-footer {
      border: 0;
      background: transparent;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    img {
      margin-right: 20px;
    }
    .accordion-body {
      border-radius: 10px 10px 0;
    }
    .topic-nav li {
      a {
        color: #fff;
        background: unset;
        -webkit-text-fill-color: unset;
        display: flex;
        align-items: center;
      }
    }
    @include media-breakpoint-down(lg) {
      border-radius: 0px !important;
    }
  }
  @include media-breakpoint-down(lg) {
    box-shadow: none;
  }
}

.egowallpopular {
  font-size: 16px;
  font-weight: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.create-first-topic {
  height: 222px;
  border-radius: $borderTwo;
  // background: $random-topaz url("../../img/cyfbg.png") center center no-repeat;
  padding: 27px;
  position: relative;
  background-color: $white;
  h2 {
    font-size: 26px;
    // height: 34px;
    font-weight: 700;
    // line-height: 24px;
    color: $gray-f4f;
  }
  p {
    font-size: 18px;
    line-height: 20px;
    color: $gray-f4f;
  }
  // .btn {
  //   margin-top: 12px;
  //   background: $white;
  //   width: 300px;
  //   &::before {
  //     background: unset;
  //   }
  // }
  i {
    background: url("../../img/cyfFrame.png") center center no-repeat;
    height: 204px;
    display: block;
    width: 326px;
    position: absolute;
    top: 10px;
    right: 9px;
  }
  // &.set-profile {
  //   text-align: left;
  //   background-image: url("../../img/sppbg.png"), url("../../img/sppFrame.png");
  //   background-position: center center, right center;
  //   background-repeat: no-repeat, no-repeat;
  //   // background: $hippie-blue url('../../img/sppbg.png') center center no-repeat, $hippie-blue url('../../img/sppFrame.png') right center repeat;
  //   padding: 26px 27px 20px 190px;
  //   p {
  //     margin-bottom: 0.5rem;
  //   }
  //   i {
  //     top: 22px;
  //     left: 53px;
  //     background: url("../../img/selfies1.png") center center no-repeat;
  //     width: 101px;
  //     height: 179px;
  //   }
  // }
  &.set-profile {
    .btn {
      background-color: $random-orange;
      border-color: $random-orange;
    }
  }
  margin-bottom: 20px;
}
//model styling
.newGroup {
  .modal-dialog {
    max-width: 600px !important;
    .modal-body {
      label.userName {
        color: $gray-f4f;
      }
    }
    .modal-header {
      padding-left: 24px;
      padding-top: 24px;
      border-bottom: none !important;
      height: 60px;
      background: $white;
    }
    .modal-title {
      font-size: 20px;
      line-height: 26px;
      color: $gray-529;
      font-weight: 700;
    }
    .btn {
      min-width: 130px;
      height: 50px;
    }
    .btn-privacy {
      width: 100%;
      height: 32px;
      background: #e7f6fd;
      border-radius: 30px;
      display: flex;
      align-items: center;
      color: #13a9e8;
      justify-content: space-between;
    }
    .pills-container {
      display: flex;
    }
    .rounded-pill {
      min-width: auto;
      width: auto;
      height: 34px;
      margin-right: 10px;
      max-width: 200px;
      float: left;
    }
  }
  .profile-info {
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-top: -4px;
  }
}

.modal-title.h4 {
  color: $gray-529 !important;
  div {
    width: 100px;
    height: 100px;
    background-color: white;
    animation-name: example;
    animation-duration: 4s;
  }

  @keyframes example {
    0% {
      background-color: blue;
    }
    25% {
      background-color: blue;
    }
    50% {
      background-color: blue;
    }
    100% {
      background-color: white;
    }
  }
}

.selectpicker {
  background: transparent;
  width: auto;
  border: none;
  margin-top: -20px;
}
.selectpicker:focus {
  outline: none;
}

// @include media-breakpoint-between(sm, md) {
//   .topic-details {
//     max-width: 100%;
//   }
// }

.img-single {
  height: 300px !important;
}

.main .dashboard-sort-filter {
  border-bottom: 1px solid $normal-gray;
  // border-bottom: none;
  // border-right: 1px solid #e0e0e0;
  // position: fixed;
  //  z-index: 1000;
  // top: 83px;
  // position: sticky;
  // top: 0;
  // background: #f4f4f4 !important;
  background: linear-gradient(180deg, #f7f7f7 0%, rgba(255, 255, 255, 0) 100%);
  width: 27.76%;
  // position: sticky;
  // top: 0;
  .tab-filter {
    border: 1px solid $gray-5f0;
    border-radius: 3px;
  }
  height: 58px;
}
.main .feeds {
  background: #f4f4f4;
}
.dashboard-sort-filter {
  //border-bottom: 1px solid #bdbdbd;
}
.cardImagesExpand {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 20px;
  font-weight: bold;
}

blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: 5px solid #ccc;
}
@keyframes quickNote {
  0% {
    background: #1ea5df;
    background-color: #1ea5df;
  }
  25% {
    background: #1ea5df;
    background-color: #1ea5df;
  }
  50% {
    background: #1ea5df;
    background-color: #1ea5df;
  }
  100% {
    background: #fff;
    background-color: #fff;
  }
}
.animate-card {
  // background-color: white !important;
  // animation-name: quickNote;
  // animation-duration: 13s;
  animation: quickNote 5s ease-in-out;
  transition: "all .5s ease";
  background-color: #fff;
}

.scroll-max {
  max-height: 380px;
  overflow: scroll;
  overflow-x: hidden;
}

.document-text {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 400px;
}

@media screen and (min-width: 1400px) {
  .container-fluid {
    max-width: 1170px !important;
  }
}
@media screen and (min-width: 1600px) {
  .container-fluid {
    max-width: 1270px !important;
  }
}
@media screen and (min-width: 1900px) {
  .container-fluid {
    max-width: 1370px !important;
  }
}
.initialModal .modal-content {
  box-shadow: none !important;
}
