header {
  width: 100%;
  background: $white;
  position: fixed;
  padding: 16px 0;
  margin-bottom: 32px;
  background-color: #fff;
  border: 0 solid #2d3436;
  border-image: none 100% 1 0 stretch;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px;
  box-sizing: border-box;
  z-index: 2;
}

.header-container {
  width: 100%;
  //max-width: 1290px;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .full-width {
    max-width: 100% !important;
  }
  .navbar {
    width: 100%;
  }
  .form-group {
    width: 60%;
    flex-grow: 0 !important;
  }
}

.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.navbar-brand img {
  margin-left: -22px;
}
.profile-icon {
  display: flex;
  align-items: center;
  figure {
    display: flex;
    align-items: center;
    margin: 0;
    figcaption {
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      color: $black !important;
      margin-left: 10px;
      // padding-right: 25px;
      cursor: pointer;
      /*background: url(../../img/icons/down-arrow-icon.svg) no-repeat right center;*/
      .nav-item a {
        color: $black !important;
      }
    }
  }
}

.navbar-light .navbar-nav .nav-link {
  color: $black !important;
}

.navbar-collapse {
  flex-grow: 0;
}

.profile-icon figure figcaption .nav-link {
  padding: 0px !important;
}

.profile-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-xsm {
  height: 46px;
  width: 46px;
}
.profile-sm {
  height: 56px;
  width: 56px;
}
.profile-xs {
  height: 36px;
  width: 36px;
}

.profile-xmd {
  height: 66px;
  width: 66px;
}

.header-search {
  width: 100%;
  min-width: 500px;
}
.profile-md {
  height: 85px;
  width: 85px;
}
.online-icon-container {
  // width: 50px;
  // height: 50px;
  position: relative;
}
.online-status-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: grey;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}
.online-status-circle-on {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #15b294;
  border: 2px solid white;
  bottom: -5px;
  right: -5px;
  position: absolute;
}
.online-status-circle-off {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ed5565;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}

.online-icon-container-small {
  width: auto;
  height: auto;
  position: relative;
}
.online-status-circle-small {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: grey;
  border: 1px solid white;
  bottom: 0;
  right: 10px;
  position: absolute;
}
.online-status-circle-on-small {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #15b294;
  border: 1px solid white;
  bottom: 0;
  right: 10px;
  position: absolute;
}
.online-status-circle-off-small {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ed5565;
  border: 1px solid white;
  bottom: 0;
  right: 10px;
  position: absolute;
}
.search-button {
  background-color: $light-gray !important;
  border-color: #ffffff !important;
  // border: 1px solid #e0e0e0 !important;
  border-left: none !important;
  height: 40px !important;
  float: right;
  min-width: 10px !important;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

$ew-avatar-sizes: 16, 24, 32, 48, 64, 96, 128 !default;

@mixin ew-avatar($size) {
  max-height: $size + px;
  width: $size + px;
  height: 100%;
  font-size: round(($size / 4) * 2) + px;
  line-height: $size + px;
}

.ew-avatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.img-thumbnail {
    padding: 4px;
    max-width: none;
  }
  & > img {
    width: 100%;
  }

  @include ew-avatar(32);
}

.avatar-bg-primary {
  background: #12a8e8 !important;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $ew-avatar-sizes {
      .ew-avatar#{$infix}-#{$size} {
        @include ew-avatar($size);
      }
    }
  }
}

.profile-lg {
  height: 120px;
  width: 120px;
}

.border-right {
  border-right: 1px solid #dce0ea;
}
