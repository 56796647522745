.ic {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}
.icn {
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  margin-right: 10px;
}
.icns {
  display: inline-block;
  width: 46px;
  height: 46px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  margin-right: 10px;
}

.ic.ic-xl {
  width: 32px;
  height: 32px;
  vertical-align: initial;
}
.ic.ic-lg {
  width: 28px;
  height: 28px;
  vertical-align: initial;
}
.ic.ic-md {
  width: 16px;
  height: 16px;
  vertical-align: initial;
}
.ic.ic-sm {
  width: 14px;
  height: 14px;
  vertical-align: initial;
}
.ic.ic-xs {
  width: 10px;
  height: 10px;
  vertical-align: initial;
}
.first_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 20px;
}

.ic-home {
  background-image: url("../../img/icons/ic-home.svg");
}
.ic-topic {
  // background-image: url("../../img/icons/ic-topic.svg");
}
.ic-media {
  background-image: url("../../img/icons/ic-media.svg");
}
.ic-add_connection1 {
  background-image: url("../../img/icons/ic-connection1.svg");
}
.ic-add_connection2 {
  background-image: url("../../img/icons/ic-connection2.svg");
}
.ic-add_connection {
  background-image: url("../../img/icons/ic-add_connection.svg");
}
.ic-add_circles {
  background-image: url("../../img/icons/ic-add_Circles.svg");
}
.ic-scroll_down {
  background-image: url("../../img/icons/ic-scroll_down.svg");
}
.ic-add_item {
  background-image: url("../../img/icons/ic-add_item.svg");
}
.ic-loading1 {
  background-image: url("../../img/icons/ic-loading1.svg");
}
.ic-circle {
  background-image: url("../../img/icons/ic-circle.svg");
}
.ic-add_circle {
  background-image: url("../../img/icons/ic-circle3.svg");
}
.ic-dollar_symbol1 {
  background-image: url("../../img/icons/ic-dollar_symbol1.svg");
}
.ic-add_circle1 {
  background-image: url("../../img/icons/ic-add_circle1.svg");
}

//    SECOND ROW

.second_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 20px;
}

.ic-add_camera {
  background-image: url("../../img/icons/second/ic-add_camera.svg");
}
.ic-arrow_left {
  background-image: url("../../img/icons/second/ic-arrow_left.svg");
}
.ic-arrow_right {
  background-image: url("../../img/icons/second/ic-arrow_right.svg");
}
.ic-add_calendar {
  background-image: url("../../img/icons/second/ic-calendar.svg");
}
.ic-filter_filled {
  background-image: url("../../img/icons/second/ic-filter_filled.svg");
}
.ic-filter {
  background-image: url("../../img/icons/second/ic-filter.svg");
}
.ic-hamburger_menu {
  background-image: url("../../img/icons/second/ic-hamburger_menu.svg");
}
.ic-maximize {
  background-image: url("../../img/icons/second/ic-maximize.svg");
}
.ic-minimize {
  background-image: url("../../img/icons/second/ic-minimize.svg");
}
.ic-more_horizontal {
  background-image: url("../../img/icons/second/ic-more_horizontal.svg");
}
.ic-move {
  background-image: url("../../img/icons/second/ic-move.svg");
}
.ic-options {
  background-image: url("../../img/icons/second/ic-options.svg");
}
.ic-rotation {
  background-image: url("../../img/icons/second/ic-rotation.svg");
}
.ic-sliders {
  background-image: url("../../img/icons/second/ic-sliders.svg");
}
.ic-circle_close {
  background-image: url("../../img/icons/second/ic-circle_close.svg");
}
.ic-circle_info {
  background-image: url("../../img/icons/second/ic-circle_info.svg");
}
.ic-file {
  background-image: url("../../img/icons/second/ic-file.svg");
}
.ic-file_text {
  background-image: url("../../img/icons/second/ic-file_text.svg");
}
.ic-folder {
  background-image: url("../../img/icons/second/ic-folder.svg");
}
.ic-phone {
  background-image: url("../../img/icons/second/ic-phone.svg");
}
.ic-profile {
  background-image: url("../../img/icons/second/ic-profile.svg");
}
.ic-clock {
  background-image: url("../../img/icons/second/ic-clock.svg");
}
.ic-phone_book {
  background-image: url("../../img/icons/second/ic-phone_book.svg");
}

// THIRD ROW

.third_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 20px;
}

.ic-open-folder-with-document1 {
  background-image: url("../../img/icons/third/ic-open-folder-with-document1.svg");
}
.ic-add_items_in_folder {
  background-image: url("../../img/icons/third/ic-add_items_in_folder.svg");
}
.ic-add_items2 {
  background-image: url("../../img/icons/third/ic-add_items2.svg");
}
.ic-left {
  background-image: url("../../img/icons/third/ic-left.svg");
}
.ic-dropdown {
  background-image: url("../../img/icons/third/ic-dropdown.svg");
}
.ic-up {
  background-image: url("../../img/icons/third/ic-up.svg");
}
.ic-right {
  background-image: url("../../img/icons/third/ic-right.svg");
}
.ic-short_active {
  background-image: url("../../img/icons/third/ic-sort_active.svg");
}
.ic-sort_default {
  background-image: url("../../img/icons/third/ic-sort_default.svg");
}
.ic-sync {
  background-image: url("../../img/icons/third/ic-sync.svg");
}
.ic-shuffle {
  background-image: url("../../img/icons/third/ic-shuffle.svg");
}
.ic-eye_on {
  background-image: url("../../img/icons/third/ic-eye_on.svg");
}
.ic-eye_off {
  background-image: url("../../img/icons/third/ic-eye_off.svg");
}
.ic-edit {
  background-image: url("../../img/icons/third/ic-edit.svg");
}
.ic-edit_2 {
  background-image: url("../../img/icons/third/ic-edit_2.svg");
}
.ic-copy {
  background-image: url("../../img/icons/third/ic-copy.svg");
}
.ic-checked {
  background-image: url("../../img/icons/third/ic-checked.svg");
}
.ic-unchecked {
  background-image: url("../../img/icons/third/ic-unchecked.svg");
}
.ic-user_plus {
  background-image: url("../../img/icons/third/ic-user_plus.svg");
}
.ic-user {
  background-image: url("../../img/icons/third/ic-user.svg");
}
.ic-users {
  background-image: url("../../img/icons/third/ic-users.svg");
}
.ic-tag {
  background-image: url("../../img/icons/third/ic-tag.svg");
}
.ic-Active {
  background-image: url("../../img/icons/third/ic-Active.svg");
}
.ic-ban_user1 {
  background-image: url("../../img/icons/third/ic-ban_user1.svg");
}

.fourth_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 20px;
}

.ic-image_count {
  background-image: url("../../img/icons/fourth/ic-image_count.svg");
}
.ic-link2 {
  background-image: url("../../img/icons/fourth/ic-link2.svg");
}
.ic-image {
  background-image: url("../../img/icons/fourth/ic-image.svg");
}
.ic-share {
  background-image: url("../../img/icons/fourth/ic-share.svg");
}
.ic-share2 {
  background-image: url("../../img/icons/fourth/ic-share2.svg");
}
.ic-search {
  background-image: url("../../img/icons/fourth/ic-search.svg");
}
.ic-settings {
  background-image: url("../../img/icons/fourth/ic-settings.svg");
}
.ic-delete {
  background-image: url("../../img/icons/fourth/ic-delete.svg");
}
.ic-delete2 {
  background-image: url("../../img/icons/fourth/ic-delete2.svg");
}
.ic-star {
  background-image: url("../../img/icons/fourth/ic-star.svg");
}
.ic-save {
  background-image: url("../../img/icons/fourth/ic-save.svg");
}
.ic-locked {
  background-image: url("../../img/icons/fourth/ic-locked.svg");
}
.ic-unlocked {
  background-image: url("../../img/icons/fourth/ic-unlocked.svg");
}
.ic-notification_on {
  background-image: url("../../img/icons/fourth/ic-notification_on.svg");
}
.ic-notification_new {
  background-image: url("../../img/icons/fourth/ic-notification_new.svg");
}
.ic-notification_off {
  background-image: url("../../img/icons/fourth/ic-notification_off.svg");
}
.ic-mail {
  background-image: url("../../img/icons/fourth/ic-mail.svg");
}
.ic-print {
  background-image: url("../../img/icons/fourth/ic-print.svg");
}
.ic-message_box_circle {
  background-image: url("../../img/icons/fourth/ic-message_box_circle.svg");
}
.ic-message {
  background-image: url("../../img/icons/fourth/ic-message.svg");
}
.ic-message_active {
  background-image: url("../../img/icons/fourth/ic-message_active.svg");
}
.ic-message2 {
  background-image: url("../../img/icons/fourth/ic-message2.svg");
}
.ic-message_counter {
  background-image: url("../../img/icons/fourth/ic-message_counter.svg");
}
.ic-logout {
  background-image: url("../../img/icons/fourth/ic-logout.svg");
}

// FIFTH ROW
.fifth_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 20px;
}
.ic-add_topic {
  background-image: url("../../img/icons/fifth/ic-add_topic.svg");
}
.ic-minus {
  background-image: url("../../img/icons/fifth/ic-minus.svg");
}
.ic-add {
  background-image: url("../../img/icons/fifth/ic-add.svg");
}
.ic-close {
  background-image: url("../../img/icons/fifth/ic-close.svg");
}
.ic-check {
  background-image: url("../../img/icons/fifth/ic-check.svg");
}
.ic-stop {
  background-image: url("../../img/icons/fifth/ic-stop.svg");
}
.ic-camera_on {
  background-image: url("../../img/icons/fifth/ic-camera_on.svg");
}
.ic-camera_off {
  background-image: url("../../img/icons/fifth/ic-camera_off.svg");
}
.ic-download {
  background-image: url("../../img/icons/fifth/ic-download.svg");
}
.ic-upload {
  background-image: url("../../img/icons/fifth/ic-upload.svg");
}
.ic-help {
  background-image: url("../../img/icons/fifth/ic-help.svg");
}
.ic-Inactive {
  background-image: url("../../img/icons/fifth/ic-Inactive.svg");
}
.ic-triangle {
  background-image: url("../../img/icons/fifth/ic-triangle.svg");
}
.ic-edit_form {
  background-image: url("../../img/icons/fifth/ic-edit_form.svg");
}
.ic-map_pin {
  background-image: url("../../img/icons/fifth/ic-map_pin.svg");
}

.ic-map {
  background-image: url("../../img/icons/fifth/ic-map.svg");
}
.ic-navigation2 {
  background-image: url("../../img/icons/fifth/ic-navigation2.svg");
}
.ic-home {
  background-image: url("../../img/icons/fifth/ic-home.svg");
}
.ic-at_sign {
  background-image: url("../../img/icons/fifth/ic-at_sign.svg");
}
.ic-gift {
  background-image: url("../../img/icons/fifth/ic-gift.svg");
}
.ic-truck {
  background-image: url("../../img/icons/fifth/ic-truck.svg");
}

// SIXTH ROW
.sixth_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 20px;
}
.ic-add_connection1 {
  background-image: url("../../img/icons/sixth/ic-add_connection1.svg");
}
.ic-ag_icon_grip {
  background-image: url("../../img/icons/sixth/ic-ag_icon_grip.svg");
}
.ic-ag-icon-group {
  background-image: url("../../img/icons/sixth/ic-ag-icon-group.svg");
}
.ic-ag-icon-cancel {
  background-image: url("../../img/icons/sixth/ic-ag-icon-cancel.svg");
}
.ic-ag-icon-small-right {
  background-image: url("../../img/icons/sixth/ic-ag-icon-small-right.svg");
}
.ic-ag-icon-small-Down {
  background-image: url("../../img/icons/sixth/ic-ag-icon-small-Down.svg");
}
.ic-column {
  background-image: url("../../img/icons/sixth/ic-column.svg");
}
.ic-pin {
  background-image: url("../../img/icons/sixth/ic-pin.svg");
}
.ic-like {
  background-image: url("../../img/icons/sixth/ic-like.svg");
}
.ic-like-active {
  background-image: url("../../img/icons/sixth/ic-like-active.svg");
}
.ic-support {
  background-image: url("../../img/icons/sixth/ic-support.svg");
}
.ic-bluetooth {
  background-image: url("../../img/icons/sixth/ic-bluetooth.svg");
}
.ic-to-do {
  background-image: url("../../img/icons/sixth/ic-to-do.svg");
}
.ic-touch {
  background-image: url("../../img/icons/sixth/ic-touch.svg");
}
.ic-xml {
  background-image: url("../../img/icons/sixth/ic-xml.svg");
}
.ic-pdf {
  background-image: url("../../img/icons/sixth/ic-pdf.svg");
}
.ic-thunder {
  background-image: url("../../img/icons/sixth/ic-thunder.svg");
}
.ic-sort {
  background-image: url("../../img/icons/sixth/ic-sort.svg");
}
.ic-drawing {
  background-image: url("../../img/icons/sixth/ic-drawing.svg");
}
.ic-stop1 {
  background-image: url("../../img/icons/sixth/ic-stop1.svg");
}
.ic-end1 {
  background-image: url("../../img/icons/sixth/ic-end1.svg");
}
.ic-play {
  background-image: url("../../img/icons/sixth/ic-play.svg");
}
.ic-LikeComment {
  background-image: url("../../img/icons/sixth/ic-Like&Comment.svg");
}

.seventh_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 20px;
}
.ic-csv {
  background-image: url("../../img/icons/seventh/ic-csv.svg");
}
.ic-Scan {
  background-image: url("../../img/icons/seventh/ic-Scan.svg");
}
.ic-logout {
  background-image: url("../../img/icons/seventh/ic-logout.svg");
}
.ic-clipboard {
  background-image: url("../../img/icons/seventh/ic-clipboard.svg");
}
.ic-toggle-on {
  background-image: url("../../img/icons/seventh/ic-toggle-on.svg");
}
.ic-toggle-offt {
  background-image: url("../../img/icons/seventh/ic-toggle-offt.svg");
}
.ic-ag-icon-small-up {
  background-image: url("../../img/icons/seventh/ic-ag-icon-small-up.svg");
}
.ic-ag-icon-small-Left {
  background-image: url("../../img/icons/seventh/ic-ag-icon-small-Left.svg");
}
.ic-Customize {
  background-image: url("../../img/icons/seventh/ic-Customize.svg");
}
.ic-rotate {
  background-image: url("../../img/icons/seventh/ic-rotate.svg");
}
.ic-Add_image {
  background-image: url("../../img/icons/seventh/ic-Add_image.svg");
}
.ic-add_pic {
  background-image: url("../../img/icons/seventh/ic-add_pic.svg");
}

.eighth_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 20px;
}

.ic-Location {
  background-image: url("../../img/icons/eighth/ic-Location.svg");
}
.ic-correct1 {
  background-image: url("../../img/icons/eighth/ic-correct1.svg");
}
.ic-PDF-Zoom-In {
  background-image: url("../../img/icons/eighth/ic-PDF-Zoom-In.svg");
}
.ic-PDF-Zoom-Out {
  background-image: url("../../img/icons/eighth/ic-PDF-Zoom-Out.svg");
}
.ic-zoom-in1 {
  background-image: url("../../img/icons/eighth/ic-zoom-in1.svg");
}
.ic-Color-circle {
  background-image: url("../../img/icons/eighth/ic-Color-circle.svg");
}
.ic-User_profile {
  background-image: url("../../img/icons/eighth/ic-User_profile.svg");
}
.ic-Swipe-delete {
  background-image: url("../../img/icons/eighth/ic-Swipe-delete.svg");
}
.ic-video {
  background-image: url("../../img/icons/eighth/ic-video.svg");
}
.ic-reply-24 {
  background-image: url("../../img/icons/eighth/ic-reply-24.svg");
}

.nineth_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 40px;
}

.ic-Inactive {
  background-image: url("../../img/icons/nineth/ic-Inactive.svg");
}
.ic-Inactive2 {
  background-image: url("../../img/icons/nineth/ic-Inactive2.svg");
}
.ic-Inactive3 {
  background-image: url("../../img/icons/nineth/ic-Inactive3.svg");
}
.ic-Inactive4 {
  background-image: url("../../img/icons/nineth/ic-Inactive4.svg");
}
.ic-Inactive5 {
  background-image: url("../../img/icons/nineth/ic-Inactive5.svg");
}
.ic-public-Inactive {
  background-image: url("../../img/icons/nineth/ic-public-Inactive.svg");
}
.ic-Dashboard {
  background-image: url("../../img/icons/nineth/ic-Dashboard.svg");
}
.ic-Active {
  background-image: url("../../img/icons/nineth/ic-Active.svg");
}
.ic-Active2 {
  background-image: url("../../img/icons/nineth/ic-Active2.svg");
}
.ic-connection-Active {
  background-image: url("../../img/icons/nineth/ic-connection-Active.svg");
}
.ic-egowall-Active {
  background-image: url("../../img/icons/nineth/ic-egowall-Active.svg");
}
.ic-oneonone-Active {
  background-image: url("../../img/icons/nineth/ic-oneonone-Active.svg");
}
.ic-public-Active {
  background-image: url("../../img/icons/nineth/ic-public-Active.svg");
}

.tenth {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 50px;
}

.ic-teams2 {
  background-image: url("../../img/icons/tenth/ic-teams2.svg");
}
.ic-Star2 {
  background-image: url("../../img/icons/tenth/ic-Star2.svg");
}
.ic-teams1 {
  background-image: url("../../img/icons/tenth/ic-teams1.svg");
}
.ic-Active {
  background-image: url("../../img/icons/tenth/ic-Active.svg");
}

.last_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 50px;
}

.ic-vector {
  background-image: url("../../img/icons/last/vector3.png");
}
.ic-friends {
  background-image: url("../../img/icons/last/ic-friends.svg");
}
.ic-family {
  background-image: url("../../img/icons/last/ic-family.svg");
}
.ic-colleagues {
  background-image: url("../../img/icons/last/ic-colleagues.svg");
}

.i-1 {
  background-image: url("../../img/icons/add-icon.svg");
}

.i-2 {
  background-image: url("../../img/icons/Addtopic.svg");
}

.i-3 {
  background-image: url("../../img/icons/back-icon.svg");
}

.i-4 {
  background-image: url("../../img/icons/Circle.svg");
}

.i-5 {
  background-image: url("../../img/icons/close-icon.svg");
}

.i-6 {
  background-image: url("../../img/icons/ColleagueColor.svg");
}

.i-7 {
  background-image: url("../../img/icons/ColleaguePlain.svg");
}

.i-8 {
  background-image: url("../../img/icons/colleagues.svg");
}

.i-9 {
  background-image: url("../../img/icons/comment-icon.svg");
}

.i-10 {
  background-image: url("../../img/icons/Comment.svg");
}

.i-11 {
  background-image: url("../../img/icons/connection-dark.svg");
}

.i-12 {
  background-image: url("../../img/icons/connection-icon-black.svg");
}

.i-13 {
  background-image: url("../../img/icons/connection-icon-white.svg");
}

.i-14 {
  background-image: url("../../img/icons/connection-icon.svg");
}

.i-15 {
  background-image: url("../../img/icons/connection.svg");
}

.i-16 {
  background-image: url("../../img/icons/connectionRightIconBlack.svg");
}

.i-17 {
  background-image: url("../../img/icons/content-sharing-icon.svg");
}

.i-18 {
  background-image: url("../../img/icons/Dashboard-dark.svg");
}

.i-19 {
  background-image: url("../../img/icons/delete.svg");
}

.i-20 {
  background-image: url("../../img/icons/down-arrow-icon.svg");
}

.i-21 {
  background-image: url("../../img/icons/edit-color.svg");
}

.i-22 {
  background-image: url("../../img/icons/edit.svg");
}

.i-23 {
  background-image: url("../../img/icons/egowal-member-black.svg");
}

.i-24 {
  background-image: url("../../img/icons/egowal-member-white.svg");
}

.i-25 {
  background-image: url("../../img/icons/egowal-member.svg");
}

.i-26 {
  background-image: url("../../img/icons/eye-close.svg");
}

.i-27 {
  background-image: url("../../img/icons/family.svg");
}

.i-28 {
  background-image: url("../../img/icons/FamilyColor.svg");
}

.i-29 {
  background-image: url("../../img/icons/FamilyPlain.svg");
}

.i-30 {
  background-image: url("../../img/icons/filters.svg");
}

.i-31 {
  background-image: url("../../img/icons/friendIcon.svg");
}

.i-32 {
  background-image: url("../../img/icons/FriendsColor.svg");
}

.i-33 {
  background-image: url("../../img/icons/FriendsPlain.svg");
}

.i-34 {
  background-image: url("../../img/icons/group-communication-icon.svg");
}

.i-35 {
  background-image: url("../../img/icons/group-dark.svg");
}

.i-36 {
  background-image: url("../../img/icons/group-icon-black.svg");
}

.i-37 {
  background-image: url("../../img/icons/group-icon-white.svg");
}

.i-38 {
  background-image: url("../../img/icons/group-icon.svg");
}

.i-39 {
  background-image: url("../../img/icons/group.svg");
}

.i-40 {
  background-image: url("../../img/icons/ic-add_circle1.svg");
}

.i-41 {
  background-image: url("../../img/icons/ic-add_Circles.svg");
}

.i-42 {
  background-image: url("../../img/icons/ic-add_connection.svg");
}

.i-43 {
  background-image: url("../../img/icons/ic-add_item.svg");
}

.i-44 {
  background-image: url("../../img/icons/ic-circle.svg");
}

.i-45 {
  background-image: url("../../img/icons/ic-circle3.svg");
}

.i-46 {
  background-image: url("../../img/icons/ic-connection1.svg");
}

.i-47 {
  background-image: url("../../img/icons/ic-connection2.svg");
}

.i-48 {
  background-image: url("../../img/icons/ic-dollar_symbol1.svg");
}

.i-49 {
  background-image: url("../../img/icons/ic-home.svg");
}

.i-50 {
  background-image: url("../../img/icons/ic-loading1.svg");
}

.i-51 {
  background-image: url("../../img/icons/ic-media.svg");
}

.i-52 {
  background-image: url("../../img/icons/ic-scroll_down.svg");
}

.i-53 {
  background-image: url("../../img/icons/ic-topic.svg");
}

.i-54 {
  background-image: url("../../img/icons/icon-eye-off.svg");
}

.i-55 {
  background-image: url("../../img/icons/icon-eye-on.svg");
}

.i-56 {
  background-image: url("../../img/icons/Inactive.svg");
}

.i-57 {
  background-image: url("../../img/icons/left-arrow-black.svg");
}

.i-58 {
  background-image: url("../../img/icons/like-icon.svg");
}

.i-59 {
  background-image: url("../../img/icons/Like.svg");
}

.i-60 {
  background-image: url("../../img/icons/more-icon-h.svg");
}

.i-61 {
  background-image: url("../../img/icons/one-one-icon-black.svg");
}

.i-62 {
  background-image: url("../../img/icons/one-one-icon-white.svg");
}

.i-63 {
  background-image: url("../../img/icons/one-one-icon.svg");
}

.i-64 {
  background-image: url("../../img/icons/oneOnOneRightblackicon.svg");
}

.i-65 {
  background-image: url("../../img/icons/private-icon-black.svg");
}

.i-66 {
  background-image: url("../../img/icons/private-icon-white.svg");
}

.i-67 {
  background-image: url("../../img/icons/private-icon.svg");
}

.i-68 {
  background-image: url("../../img/icons/Private.svg");
}

.i-69 {
  background-image: url("../../img/icons/public-icon-black.svg");
}

.i-70 {
  background-image: url("../../img/icons/public-icon-white.svg");
}

.i-71 {
  background-image: url("../../img/icons/public-icon.svg");
}

.i-72 {
  background-image: url("../../img/icons/radio-checked.svg");
}

.i-73 {
  background-image: url("../../img/icons/radio.svg");
}

.i-74 {
  background-image: url("../../img/icons/recent-share-between.svg");
}

.i-75 {
  background-image: url("../../img/icons/recent.svg");
}

.i-76 {
  background-image: url("../../img/icons/search-grey.svg");
}

.i-77 {
  background-image: url("../../img/icons/share-dark.svg");
}

.i-78 {
  background-image: url("../../img/icons/share.svg");
}

.i-79 {
  background-image: url("../../img/icons/topic-details-one-on-one.svg");
}

.i-80 {
  background-image: url("../../img/icons/topic-icon.svg");
}

.i-81 {
  background-image: url("../../img/icons/upload-dark.svg");
}

.i-82 {
  background-image: url("../../img/icons/upload.svg");
}
