.stacked-sidebar {
  display: flex;
  flex: 0 0 auto;
  flex-shrink: 0;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  // transition-duration: 0.2s;
  // transition-property: all;
  // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 20;
}
.stacked-sidebar-mini {
  width: 80px;
  //background-color: $bashful-blue;
  background: linear-gradient(180deg, #006e9c 0%, #13a9e8 100%);
  //border-right: 1px solid #00597e;
}
.stacked-sidebar-mini {
  .menu-item {
    align-items: center;
    position: relative;
    border-radius: 0.375rem;
    -webkit-column-gap: 0.5rem;
    column-gap: 0.5rem;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    white-space: nowrap;
    // width: 100%;
    // height: 30px;
    .stacked-mini-count-wrp {
      position: absolute;
      right: -6px;
      width: 19px;
      height: 19px;
      background-color: $random-red;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      top: -6px;
    }
  }
  li {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.06);
    border-radius: 5px;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
  img {
    display: block;
    vertical-align: middle;
    height: 32px;
    padding: 6px;
  }
  .active {
    --tw-bg-opacity: 0.3;
    --tw-text-opacity: 1;
    background-color: #ff9d2f;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
    border-radius: 5px;
    border: 2px solid #ff9d2f;
    margin-left: -2px;
  }
}
.topic-main.dashboard .topic-nav li a {
  color: #fff !important;
}
// .topic-main {
//   max-height: 400px !important;
// }
.recent-share-list ul li {
  display: flex;
  align-items: center;
  margin: 0px 0;
}
.recent-share-list {
  height: 100%;
}
.create-active {
  //padding: 3px;
  //background: #ff9d2f;
  border-radius: 50%;
}
.accordion-connections {
  max-height: 750px;
  overflow-y: scroll;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
}
.topic-details-topics-sidebar .accordion {
  max-height: 100%;
}

.accordion-connections::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.accordion-connections ul li p {
  margin: 0;
  font-size: 15px;
  line-height: 16px;
  font-weight: 400;
  color: $gray-529;
  margin-left: 10px;
  width: 100%;
  overflow: hidden;
  overflow-wrap: break-word;
}
.accordion-connections ul li div img {
  margin-right: 10px;
}

.dashboard .accordion .accordio-body .topic-nav ul {
  list-style: none;
}
.recent-topics-sidebar {
  //background-color: rgb(0, 120, 171);
  margin-left: 10px;
  margin-top: 10px;
  color: $white;
  max-height: 220px;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .recent-topics-sidebar-items {
    padding: 10px;
    display: flex;
    label {
      width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.recent-topics-sidebar .details-color {
  color: #fff;
  width: 95%;
}
.recent-topics-sidebar .active {
  background-color: rgba(84, 189, 234, 0.9) !important;
  border-radius: 2px;
  color: #fff !important;
}
.accordion-connections {
  padding: 0px;
}
.accordion-footer-recent-share {
  padding: 15px 14px;
  background: #006e9c;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  .btn-back {
    font-size: 18px;
  }
}
.recent-topics-sidebar .recent-topics-sidebar-items label {
  font-size: 15px;
}
.topic-nav .accordion-connections li a {
  line-height: 1 !important;
  padding: 0 !important;
  display: contents !important;
}
.recent-share-user {
  padding: 8px;
}
.recent-notification-count {
  background-color: $brink-blue;
  color: #ffffff;
  position: relative;
  /* top: -35%; */
  right: 0px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 11px;
  font-weight: bold;
  float: right;
}

.sidebar-left.topic-main {
  .topic-nav {
    ul {
      overflow: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
.filter-bg-white {
  background: #fff !important;
}
