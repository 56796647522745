// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

// opacity
@mixin Opacity($value) {
  $IEValue: $value * 100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $IEValue +
    ")";
  filter: alpha(opacity=$IEValue);
}

// image-holder
// ---------------------------------------------------
@mixin image-holder(
  $width: 30px,
  $height: 30px,
  $border-radius: 50%,
  $border-size: 2px,
  $border-color: $primary
) {
  width: $width;
  height: $height;
  overflow: hidden;
  border-radius: $border-radius;
  border: $border-size solid $border-color;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// Custom Scrollbar
// ---------------------------------------------------
@mixin custom-scrollbar(
  $background: #000000,
  $width: 6px,
  $height: 6px,
  $border-radius: 8px
) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background: $background;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: $background;
  }
}

// Breakpoints
$breakpoints: (
  "large": (
    max-width: 1024px,
  ),
  "medium": (
    max-width: 992px,
  ),
  "small": (
    max-width: 767px,
  ),
  "xsmall": (
    max-width: 479px,
  ),
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Gradient Mixin
@mixin radial-gradient($color1, $color2) {
  background: linear-gradient(90deg, $color1 0%, $color2 100%);
}
