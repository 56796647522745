/**
 * Basic typography style for copy text
 */

// Fonts
// ---------------------------------------------------
@font-face {
  font-family: "Mulish";
  src: url("../../fonts/Mulish-Bold.woff2") format("woff2"),
    url("../../fonts/Mulish-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url("../../fonts/Mulish-Regular.woff2") format("woff2"),
    url("../../fonts/Mulish-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url("../../fonts/Mulish-SemiBold.woff2") format("woff2"),
    url("../../fonts/Mulish-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Oddlyou-Lato;
  font-style: normal;
  font-weight: 300;
  src: local("☺"), url("../../fonts/Lato-Light.woff2") format("woff2"),
    url("../../fonts/Lato-Light.woff") format("woff");
  unicode-range: u+0000-f8fe, u+f900-ffff;
}

@font-face {
  font-family: Oddlyou-Lato;
  font-style: normal;
  font-weight: 400;
  src: local("☺"), url("../../fonts/Lato-Regular.woff2") format("woff2"),
    url("../../fonts/Lato-Regular.woff") format("woff");
  unicode-range: u+0000-2157, u+2159-f8fe, u+f900-ffff;
}

@font-face {
  font-family: Oddlyou-Lato;
  font-style: normal;
  font-weight: 700;
  src: local("☺"), url("../../fonts/Lato-Bold.woff2") format("woff2"),
    url("../../fonts/Lato-Bold.woff") format("woff");
  unicode-range: u+0000-f8fe, u+f900-ffff;
}

@font-face {
  font-family: Oddlyou-Lato;
  font-style: normal;
  font-weight: 900;
  src: local("☺"), url("../../fonts/Lato-Black.woff2") format("woff2"),
    url("../../fonts/Lato-Black.woff") format("woff");
  unicode-range: u+0000-f8fe, u+f900-ffff;
}

@font-face {
  font-family: Oddlyou-Lato;
  font-style: italic;
  font-weight: 300;
  src: local("☺"), url("../../fonts/Lato-Italic-Light.woff2") format("woff2"),
    url("../../fonts/Lato-Italic-Light.woff") format("woff");
  unicode-range: u+0000-2259, u+2261-f8fe, u+f900-ffff;
}

@font-face {
  font-family: Oddlyou-Lato;
  font-style: italic;
  font-weight: 400;
  src: local("☺"), url("../../fonts/Lato-Italic.woff2") format("woff2"),
    url("../../fonts/Lato-Italic.woff") format("woff");
  unicode-range: u+0000-2259, u+2261-f8fe, u+f900-ffff;
}

@font-face {
  font-family: Oddlyou-Lato;
  font-style: italic;
  font-weight: 700;
  src: local("☺"), url("../../fonts/Lato-Italic-Bold.woff2") format("woff2"),
    url("../../fonts/Lato-Italic-Bold.woff") format("woff");
  unicode-range: u+0000-2259, u+2261-f8fe, u+f900-ffff;
}

@font-face {
  font-family: Oddlyou-Lato;
  font-style: italic;
  font-weight: 900;
  src: local("☺"), url("../../fonts/Lato-Italic-Black.woff2") format("woff2"),
    url("../../fonts/Lato-Italic-Black.woff") format("woff");
  unicode-range: u+0000-2259, u+2261-f8fe, u+f900-ffff;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: local("☺"), url("../../fonts/Lato-Latin-Latin-Ext.woff2") format("woff2");
  unicode-range: u+0100-024f, u+0259, u+1e??, u+2020, u+20a0-20ab, u+20ad-20cf,
    u+2113, u+2c60-2c7f, u+a720-a7ff;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: local("☺"), url("../../fonts/Lato-Latin-Latin-Ext.woff2") format("woff2");
  unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da,
    u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215,
    u+feff, u+fffd;
}

// Headings
// ---------------------------------------------------
body {
  font-family: Oddlyou-Lato, Lato, appleLogo, sans-serif;
  background: $body-bg;
}

// Headings
// ---------------------------------------------------
h1,
.h1 {
  font-size: 2.5rem;
}
h2,
.h2 {
  font-size: 2rem;
}
h3,
.h3 {
  font-size: 1.75rem;
}
h4,
.h4 {
  font-size: 1.5rem;
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1.125rem;
}

// Custom
// ---------------------------------------------------
.text-para {
  font-size: 14px;
  line-height: 16px;
}

.text-para-large {
  font-size: 16px;
  line-height: 18px;
}

.text-description {
  font-size: 12px;
  line-height: 14px;
}

// Font Sizes
// ---------------------------------------------------
.fz-10 {
  font-size: 10px;
}
.fz-11 {
  font-size: 11px;
}
.fz-12 {
  font-size: 12px;
}
.fz-13 {
  font-size: 13px;
}
.fz-14 {
  font-size: 14px;
}
.fz-15 {
  font-size: 15px;
}
.fz-16 {
  font-size: 16px;
}
.fz-17 {
  font-size: 17px;
}
.fz-18 {
  font-size: 18px;
}
.fz-19 {
  font-size: 19px;
}
.fz-20 {
  font-size: 20px;
}
.fz-21 {
  font-size: 21px;
}
.fz-22 {
  font-size: 22px;
}
.fz-23 {
  font-size: 23px;
}
.fz-24 {
  font-size: 24px;
}
.fz-25 {
  font-size: 25px;
}
.fz-26 {
  font-size: 26px;
}
.fz-27 {
  font-size: 27px;
}
.fz-28 {
  font-size: 28px;
}
.fz-29 {
  font-size: 29px;
}
.fz-30 {
  font-size: 30px;
}
.fz-31 {
  font-size: 31px;
}
.fz-32 {
  font-size: 32px;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
