.groups {
  .connection {
    padding: 20px;
    border-bottom: 1px solid #bdbdbd;
    border-radius: 0px;
    img {
      vertical-align: sub;
    }
    .intro {
      display: inline-block;
    }
    figcaption {
      margin-left: 10px;
      display: inline-flex;
      vertical-align: top;
      padding-top: 10px;
    }
  }
  .card {
    border-radius: $borderTwo;
    padding: 14px 20px;
  }
  .group {
    border-bottom: 1px solid #bdbdbd;
  }

  .left-panel {
    .header {
      height: 64px;
      position: relative;
    }

    .content {
      background: white;
      height: 96px;
      padding-top: 40px;
    }
    .profile-info {
      img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 30px;
        display: block;
        margin: 0 auto;
      }
    }
    .accordion-footer {
      background: $lik-bg-blue;
      color: black;
      .btn-back {
        color: black !important;
        -webkit-text-fill-color: unset;
      }
    }
  }
  .memAddBtn {
    width: 100%;
    max-width: 242px;
    margin: 0px auto;
    margin-top: 20px;
    @include media-breakpoint-down(lg) {
      margin-top: 20px;
    }
  }
  .group-list {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.members {
  align-items: center;
  display: inline-flex;
  justify-content: right;
}

.circleType {
  margin-right: 30px;
  margin-top: 22px;
  text-align: center;
  .circleTypeLabel {
    font-weight: 700;
    color: $gray-282;
    margin: 10px 0px;
    font-size: 20px;
  }
}

.modal-footer {
  border-top: none;
}

.leftPanelAcc {
  margin-bottom: 3px;
}

.leftPanelAccHead {
  background: transparent;
  background: transparent;
  border-radius: 0px;
  padding: 0px;
  .accordion-button {
    background-color: $white;
  }
  .accordion-button:not(.collapsed) {
    background-color: $white;
    color: $black;
    font-weight: 700;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
}
.leftPanelAccItem {
  border: none;
}
figure.profile-info {
  align-items: center;
}
