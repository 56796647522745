// just wrap entire file withing theme
//.company-3-theme {
/*  =Header
-------------------------------------------------------------- */

/*  =Container
-------------------------------------------------------------- */
// .content-wrapper {
//   overflow-y: hidden;
// }

// .content-inside {
//   //min-height: calc(100vh - 130px);
//   min-height: calc(100vh - 64px);
//   position: relative;
//   overflow-x: auto;

//   .content-area {
//     //padding: 24px 100px;
//     padding: 0px 20px;
//   }
//   .grid-area{
//     padding: 0px;
//   }
// }

/*  =Sidebar
-------------------------------------------------------------- */
// .sidebar {
//   position: relative;
//   float: left;
//   width: 240px;
//   //background: $sidebar-bg;
//   -webkit-transition: all 0.1s;
//   transition: all 0.1s;
//   height: 100vh;

//   // shrink state
//   &.shrink {
//     width: 60px;

//     a span {
//       display: none;
//     }
//   }

//   &.show {
//     margin-left: 0;
//   }

//   // navbar

//   // Menu list
//   // ul {

//   //   li {
//   //     margin: 0 0;

//   //     &:first-child {
//   //       margin-top: 16px;
//   //     }

//   //     &:last-child {
//   //       margin-bottom: 0;
//   //     }
//   //     a {
//   //       color: $gray-042;
//   //     }
//   //   }

//   //   a {
//   //     padding: 0 12px;
//   //     display: block;
//   //     height: 50px;
//   //     line-height: 50px;
//   //     white-space: nowrap;

//   //     strong {
//   //       font-weight: 400;
//   //       display: inline-block;
//   //       //width: 40px;
//   //       text-align: center;
//   //       //background: red;
//   //     }

//   //     span {
//   //       vertical-align: middle;
//   //     }

//   //     i.ic {
//   //       width: 34px;
//   //       height: 34px;

//   //     }

//   //     &:hover {
//   //       text-decoration: none;
//   //       background: $gray-oeo;
//   //     }

//   //     &.active {
//   //       opacity: 1;
//   //       background: $gray-oeo;
//   //       color: $primary;
//   //       font-weight: bold;
//   //     }

//   //   }

//   //   i {
//   //     width: 32px;
//   //     height: 32px;
//   //     background-image: url("./assets/images/icons/sidebar-sprite.svg");
//   //     background-repeat: no-repeat;
//   //     display: inline-block;
//   //     vertical-align: middle;
//   //   }

//   //   // Dashboard
//   //   i.dashboard {
//   //     background-position: 0 0;
//   //   }

//   //   .active i.dashboard {
//   //     background-position: 100% 0;
//   //   }

//   //   // Order
//   //   i.order-inquiry {
//   //     background-position: 0 -32px;
//   //   }

//   //   .active i.order-inquiry {
//   //     background-position: 100% -32px;
//   //   }

//   //   // parts-inquiry
//   //   i.parts-inquiry {
//   //     background-position: 0 -64px;
//   //   }

//   //   .active i.parts-inquiry {
//   //     background-position: 100% -64px;
//   //   }

//   //   // customer-inquiry
//   //   i.customer-inquiry {
//   //     background-position: 0 -96px;
//   //   }

//   //   .active i.customer-inquiry {
//   //     background-position: 100% -96px;
//   //   }

//   //   // prospect
//   //   i.prospect {
//   //     background-position: 0 -128px;
//   //   }

//   //   .active i.prospect {
//   //     background-position: 100% -128px;
//   //   }

//   //   // report
//   //   i.report {
//   //     background-position: 0 -160px;
//   //   }

//   //   .active i.report {
//   //     background-position: 100% -160px;
//   //   }

//   //   // settings
//   //   i.settings {
//   //     background-position: 0 -192px;
//   //   }

//   //   .active i.settings {
//   //     background-position: 100% -192px;
//   //   }

//   //   // logout
//   //   i.logout {
//   //     background-position: 0 -224px;
//   //   }

//   //   .active i.logout {
//   //     background-position: 100% -224px;
//   //   }
//   // }

//   // bg
//   .bg {
//     width: 100%;
//     height: 5000px;
//     position: absolute;
//     //background: $sidebar-bg;
//   }
// }

// .shrink .main-logo {
//   display: none !important;
// }

@media only screen and (max-width: 768px) {
  // .sidebar {
  //   width: 60px;
  // }
  // .sidebar a span,
  // .sidebar .main-logo {
  //   display: none !important;
  // }
}

// Media queries
// ---------------------------------------------------

// Extra small devices
// (portrait phones, less than 576px)
// ---------------------------------------------------
// @include media-breakpoint-down(xs) {
//   .non-shrink.show {
//     overflow: hidden;
//   }

//   // Sidebar
//   .sidebar {
//     position: fixed;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 99;
//     top: 71px;

//     width: auto;

//     &.shrink.show {
//       display: none;
//     }
//   }
// }
.rounded-circle {
  border-radius: 4px !important;
}
.sidebar {
  .active {
    background: rgba(255, 255, 255, 0.1) !important;
    color: #fff !important;
    border-right: 4px solid $neon-carrot;
  }
  .details-color {
    color: #828282;
  }
  .side-folder {
    //@include radial-gradient($bashful-blue, $brink-blue);
    //background-color: radial-gradient($bashful-blue, $brink-blue);
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    color: #ffffff;
    padding: 14px;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: $borderTwo;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/*  =404
-------------------------------------------------------------- */
.page-not-found {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
  margin-top: 150px;

  .btn {
    width: 290px;
    height: 44px;
    background-color: $primary;
    color: white;
    font-size: 12px;
    margin-top: 32px;
  }
}

//----------
.card {
  border-radius: $borderTwo;
  overflow: hidden;
  border: none;
  @include media-breakpoint-down(lg) {
    border-radius: 0px !important;
  }
}

.editpostoption {
  z-index: 15;
  display: none;
  position: absolute;
  right: 0;
  bottom: 100%;
  // width: 46px;
  height: fit-content;
  //background-color: $white;
  background: #e7f6fd;
  padding: 5px 10px;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  border: 1px solid $gray-0e0;
  &.active {
    display: flex;
    top: 5px;
    right: 10px;
    z-index: 12;
    align-items: center;
  }
  button {
    background: transparent;
    text-align: center;
    border: none;
    width: 100%;
    // margin-bottom: 15px;
    color: $black;
    font-size: 14px;
    line-height: 16px;
    padding: 0px 10px;
  }
}

//
// ---------
.show-more {
  border-radius: 0 0 10px 10px !important;
  height: 130px;
  text-align: center;
  background: white;
  align-items: center;
  .btn {
    width: 250px;
  }
  @include media-breakpoint-down(lg) {
    border-radius: 0px !important;
  }
}

.card {
  &.top {
    border-bottom: none;
    margin-bottom: 0px;
    border-radius: $borderTwo !important;
    @include media-breakpoint-down(lg) {
      border-radius: 0px !important;
      border: none;
    }
  }
  &.top-1 {
    border-bottom: none;
    margin-bottom: 0px;
    border-radius: 0px !important;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  &.list {
    border-radius: 0px !important;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  &.no-record {
    border-radius: 0px 0px 10px 10px !important;
    padding: 150px 90px !important;
    .btn {
      width: 250px;
    }
  }
  @include media-breakpoint-down(lg) {
    border: none;
  }
}

//
// -----------------
.search-input {
  input {
    padding-left: 50px;
    border-radius: $borderTwo;
    color: #bbbbbb;
  }
  position: relative;
  img {
    position: absolute;
    top: 0;
    padding: 12px 0px 12px 20px;
  }
}

.row {
  width: 100%;
  @include media-breakpoint-down(lg) {
    margin: 0px;
    --bs-gutter-x: 0;
    margin-top: 80px !important;
    margin-bottom: 5px;
  }
}

.row-flex {
  display: flex;
}

.row-space-between {
  justify-content: space-between;
}
.row-align-center {
  align-items: center;
}

.profile-row {
  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.edit-profile-row {
  display: flex;
  @include media-breakpoint-down(lg) {
    display: block;
  }
}
.row-wrap {
  flex-wrap: wrap;
}

.lg-br-mb {
  margin-bottom: 30px;
}

.row-space-center {
  justify-content: center;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.row-align-end {
  align-items: flex-end;
}

.row-column {
  flex-direction: column;
}

.dropdown-toggle {
  &::after {
    display: none;
  }
}

.dropdown-menu {
  padding: 0px;
  .dropdown-menu[data-bs-popper] {
    left: -60px;
  }
}
.dropdown-item {
  padding: 0.5rem 1rem;
  &:hover {
    color: $white;
    background-color: $neon-carrot;
  }
  &:focus {
    color: $white;
    background-color: $neon-carrot;
  }
}

.dropdown-item.active {
  color: $white;
  background-color: $neon-carrot;
}
.notification .dropdown-item {
  padding: 1rem;
  padding-bottom: 0.25rem;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: $bashful-blue;
  width: 20px;
  height: 20px;
  border: 2px solid $normal-gray;
  border-radius: 3px;
  display: grid;
  place-content: center;
  min-height: 20px;
}

input[type="checkbox"]::before {
  content: "";
  width: 8px;
  height: 8px;
  // clip-path: polygon(8% 53%, 0 65%, 50% 100%, 100% 16%, 89% 11%, 46% 81%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background-color: $random-orange;
  border-radius: 2px;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
input[type="checkbox"]:checked {
  background-image: none;
  border: 2px solid $random-orange;
}

// .round-checkbox input[type="checkbox"]::before {
//   content: "";
//   width: 8px;
//   height: 8px;
//   // clip-path: polygon(8% 53%, 0 65%, 50% 100%, 100% 16%, 89% 11%, 46% 81%);
//   transform: scale(1);
//   transform-origin: bottom left;
//   transition: 120ms transform ease-in-out;
//   background-color: $random-orange;
//   border-radius: 2px;
// }

.modal-header {
  height: 50px;
  background: $bashful-blue;
}
.carousel-control-prev {
  opacity: 1;
  z-index: 0;
}
.carousel-control-next {
  opacity: 1;
  z-index: 0;
}

.carousel-control-prev-icon {
  background-color: $white;
  background-image: url("../../img/icons/dashboard/leftArrowBlack.svg");
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: 13px;
  background-position: center;
}

.carousel-control-next-icon {
  background-color: $white;
  background-image: url("../../img/icons/dashboard/rightArrowBlack.svg");
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: 13px;
  background-position: center;
}

.btn-close {
  opacity: 1;
}

.topicAlbumModal {
  .modal-footer {
    justify-content: space-around;
  }
  .modal-body {
    padding: 0;
  }
}

.editConnoption {
  z-index: 99;
  display: none;
  position: absolute;
  right: 0;
  bottom: 100%;
  width: 149px;
  height: fit-content;
  background-color: $white;
  padding: 15px 10px;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  &.active {
    display: block;
    top: 30px;
    right: 20px;
  }
  button {
    background: transparent;
    text-align: left;
    border: none;
    width: 100%;
    margin-bottom: 15px;
    color: $black;
    font-size: 14px;
    line-height: 16px;
  }
}

.searchMember {
  .input-group-text {
    background-color: $light-gray;
    border: 0;
    border-radius: $borderFive;
  }
}
.edit-topic-radio {
  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: $bashful-blue;
    width: 20px;
    height: 20px;
    border: 2px solid $normal-gray;
    border-radius: $borderThree;
    // transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 8px;
    height: 8px;
    // clip-path: polygon(8% 53%, 0 65%, 50% 100%, 100% 16%, 89% 11%, 46% 81%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: $random-orange;
    border-radius: $borderTwo;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  input[type="radio"]:checked {
    // background-color: $random-orange;
    background-image: none;
    border: 2px solid $random-orange;
  }
}
.AccMod {
  max-height: 435px;
  overflow-y: scroll;
}

.AccModItem {
  border: none;
}

.AccModHeader {
  background-color: #fff;
  padding: 17px 14px 17px 0px !important;
  .accordion-button {
    background-color: #fff;
    padding: 0px;
    &:focus {
      box-shadow: none;
      border: none;
    }
  }
}

.AccModBody {
  padding: 0px 30px;
}

.editSuggoption {
  z-index: 99;
  display: none;
  position: absolute;
  right: 0;
  bottom: 100%;
  width: 149px;
  height: fit-content;
  background-color: $white;
  padding: 15px 10px;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  &.active {
    display: block;
    top: 20px;
    right: 10px;
  }
  button {
    background: transparent;
    text-align: left;
    border: none;
    width: 100%;
    margin-bottom: 15px;
    color: $black;
    font-size: 14px;
    line-height: 16px;
  }
}

.conversationWrp {
  background-color: $lime-blue;
  text-align: center;
  word-wrap: break-word;
}

.border-bottom {
  border-bottom: 1px solid $gray-dbd;
}

.carousel-inner {
  overflow: hidden;
}
.uploadDetails {
  .carousel-inner {
    overflow: hidden;
  }
}
.float-right {
  float: right;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}

.notification {
  .dropdown-menu {
    width: 300px;
    overflow: hidden;
    .notification-thumbnail {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .dropdown-menu[data-bs-popper] {
    left: -250%;
    top: 150%;
  }
}
.header-container {
  .profile-icon {
    .navbar-brand {
      margin-right: 0;
      margin: 0px 10px;
      .navbar-text {
        font-size: 14px;
        color: $black;
        font-weight: bold;
      }
    }
    .all-notification {
      text-align: center;
      color: $brink-blue;
    }
    .no-notification {
      justify-content: center;
      display: flex;
      text-align: center;
      font-size: 12px;
    }
    .notification-icon-wrp {
      position: relative;
      background-color: $light-gray;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $borderSix;

      .notification-count {
        background-color: $error;
        color: $white;
        position: absolute;
        top: -35%;
        left: 35%;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 11px;
        font-weight: bold;
      }
    }
    .nameDropDown {
      .dropdown-menu[data-bs-popper] {
        left: -60px;
        top: 285%;
      }
    }
  }
}

.no-user-pic-profile {
  background-color: $brink-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
}
.form-floating {
  .ant-select-focused {
    box-shadow: none;
    outline: none;
  }
  .ant-select {
    // width: 100%;
    background-color: $light-gray;
    height: 40px;
    border-radius: $borderFive;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    // height: 38px;
    border-radius: 0.25rem;
    border: none;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none !important;
    padding: 0px;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .form-control {
    border-radius: $borderFive;
    padding-top: 8px !important;
    // padding-top: 5px !important;
  }
}

.ant-select-dropdown {
  z-index: 1055;
}

.modal-content {
  --SHADOW: 0 12px 28px 0#00000033, 0 2px 4px 0#0000001a,
    inset 0 0 0 1px#ffffff80;
  -webkit-box-shadow: 0 12px 28px 0#00000033, 0 2px 4px 0#0000001a,
    inset 0 0 0 1px#ffffff80;
  box-shadow: 0 12px 28px 0#00000033, 0 2px 4px 0#0000001a,
    inset 0 0 0 1px#ffffff80;
  border: none !important;
}

.task-note {
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: $neon-carrot;
    width: 24px;
    height: 24px;
    border: 2px solid $neon-carrot;
    border-radius: 12px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    background-color: $white;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 24px;
    height: 24px;
    // clip-path: polygon(8% 3%, 0 65%, 50% 100%, 100% 16%, 89% 11%, 46% 81%);
    clip-path: polygon(8% 3%, 0 65%, 50% 100%, 100% 16%, 0% 0%, 0% 0%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    //background-color: $white;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  input[type="checkbox"]:checked {
    // background-color: $neon-carrot;
    border: 2px solid $neon-carrot;
    &::before {
      clip-path: circle(30% at 50% 50%);
      background-color: $neon-carrot;
    }
  }
  // .form-check-input:checked[type="checkbox"]
}

.suggestions-list-show {
  height: 87vh;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebar-scroll {
  height: 82vh;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.profile-feeds {
  overflow: hidden;
  overflow-y: scroll;
  height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }
}

.folder-img-row {
  .folder-select {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  input[type="checkbox"] {
    width: 25px;
    height: 25px;
    border-radius: 13px;
    background-color: transparent;
    border-color: #fff;
  }
  input[type="checkbox"]::before {
    background-color: transparent;
  }
  input[type="checkbox"]:checked {
    border-color: #fff;
    background-color: $new-blue;
  }
}
