// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

// sticky footer
// body>app-root,
// body>app-root>app-layout,
// body>app-root>app-login {
//   height: 100%;
//   flex-direction: column;
//   display: flex;
// }

a {
  text-decoration: none;
}

.container-fluid {
  width: 100%;
  max-width: 1290px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
  @include media-breakpoint-down(lg) {
    padding: 0px;
  }
}

.layout-full-max {
  margin: 0px;
  background: linear-gradient(180deg, #f7f7f7 0%, rgba(255, 255, 255, 0) 100%);
  // padding-top: 83px;
  height: 100vh;
  overflow: hidden;
  //padding-top: 83px;
}

figure {
  margin: 0;
}

p {
  margin-bottom: 0px;
}

.env-banner {
  width: 100%;
  font-weight: bold;
  z-index: 1031;
  font-size: 14px;
  text-align: center;
  border-radius: 0px;
  color: #fff;
  background-color: #e2669a;
  padding: 10px;
  text-transform: uppercase;
}

.env-banner-testing {
  background-color: #2db2cf;
}

.env-banner-staging {
  background-color: #36c55e;
}
